import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import {
  missionMemberReportListApi,
  missionMemberReportListApiV1,
} from "src/DAL/AssessmentApi/assessmentApi";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { s3baseUrl } from "src/config/config";
import { dd_date_format } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function MissionMemberReportList({ type }) {
  const navigate = useNavigate();
  const { isAccess } = useContentSetting();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [searchText, setSearchText] = useState("");

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("report_member" + type, searchText);

    getConsultantListing(searchText);
  };

  const getConsultantListing = async (text) => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      let include_members =
        isAccess.show_members_list_for_payment_request == "all_members"
          ? "all"
          : "nurture";
      const result = await missionMemberReportListApiV1(
        page,
        rowsPerPage,
        text,
        include_members,
        type
      );
      if (result.code === 200) {
        console.log(
          result,
          "missionMemberReportListApimissionMemberReportListApi"
        );

        const data = result.members.map((val, index) => {
          let UserName = "N/A";
          let email = "N/A";
          if (val?.member && Object.keys(val?.member).length > 0) {
            UserName = val?.member?.first_name + " " + val?.member?.last_name;
            email = val?.member?.email;
          }
          return {
            ...val,
            Username: UserName,
            email: email,
            mission: val?.mission_info?.title,
            mission_completed_date: dd_date_format(val?.mission_completed_date),
            mission_start_date: dd_date_format(val?.mission_start_date),
          };
        });
        setUserList(data);
        setTotalPages(result.total_pages);
        setTotalCount(result.total_member_count);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleEdit = (value) => {
    console.log(value, "valuevalue");
    if (type == "completed") {
      navigate(
        `/mission-member-report/mission/mission-detail/${value?.member?._id}/${value?.mission_info?._id}`,
        {
          state: value,
        }
      );
    } else {
      navigate(
        `/inprogress-mission-report/mission/mission-detail/${value?.member?._id}/${value?.mission_info?._id}`,
        {
          state: value,
        }
      );
    }
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteDoc.id;
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  useEffect(() => {
    let search_text = localStorage.getItem("report_member" + type);
    if (search_text) {
      setSearchText(search_text);
      getConsultantListing(search_text);
    } else {
      setSearchText("");
      getConsultantListing("");
    }
  }, [page, rowsPerPage, type]);
  useEffect(() => {
    setPage(0);
    setPageCount(1);
  }, [type]);
  const MENU_OPTIONS = [
    {
      label: "View Missions",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
  ];
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  let TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },

    { id: "number", label: "#", type: "number" },
    {
      id: "profile",
      label: "Profile",
      renderData: (row) => {
        return (
          <span onClick={() => handleEdit(row)} className="pointer">
            <Avatar
              className="avatar-adjust"
              sx={{ width: 40, height: 40 }}
              alt={row?.member?.first_name}
              src={s3baseUrl + row?.member?.profile_image}
            />
          </span>
        );
      },
    },
    {
      id: "email",
      label: "Name",
      renderData: (row) => {
        return (
          <span onClick={() => handleEdit(row)} className="pointer">
            {" "}
            {htmlDecode(row.Username)}
          </span>
        );
      },
    },
    { id: "email", label: "Email" },
    { id: "mission", label: "Mission Title" },

    { id: "mission_duration", label: "Mission Duration (Days)" },
    { id: "mission_attracted_coins", label: "Coins Earned" },
    { id: "mission_reward_coins", label: "Achievable Coins" },
    { id: "mission_start_date", label: "Mission Start Date" },
    { id: "mission_completed_date", label: "Mission Completed Date" },
  ];
  let filteredTableHead = [];

  filteredTableHead =
    type == "in_progress" ? TABLE_HEAD.slice(0, -1) : TABLE_HEAD;

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <h2>
              {type == "completed"
                ? "Completed Misions Report"
                : "Inprogress Misions Report"}
            </h2>
          </div>
        </div>

        <div className="col-12 mt-4">
          <CustomMUITable
            TABLE_HEAD={filteredTableHead}
            data={userList}
            selected={selected}
            MENU_OPTIONS={MENU_OPTIONS}
            setSelected={setSelected}
            className="card-with-background"
            custom_pagination={{
              total_count: totalCount,
              rows_per_page: rowsPerPage,
              page: page,
              handleChangePage: handleChangePage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            custom_search={{
              searchText: searchText,
              setSearchText: setSearchText,
              handleSubmit: searchFunction,
            }}
            pageCount={pageCount}
            totalPages={totalPages}
            handleChangePages={handleChangePages}
            pagePagination={true}
          />
        </div>
      </div>
    </>
  );
}
