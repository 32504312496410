import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Pagination, TablePagination } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import FilteredChip from "src/components/GeneralComponents/FilteredChip";
import { pods_list_api_v1 } from "src/DAL/delegatePods/delegatePods";
import PodsFilter from "./PodsFilter";
import { s3baseUrl } from "src/config/config";
import { DeletePod } from "src/DAL/Pods/Pods";
import RecordNotFound from "src/components/RecordNotFound";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "src/components/CustomPopover";
import UserListToolbarsForSearch from "src/components/_dashboard/user/UserListToolbarsForSearch";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CalendarPodsList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [filterDrawerState, setFilterDrawerState] = useState(false);

  const EMPTY_FILTER = {
    badge_levels: [],
    pod_type: "all",
    search_text: "",
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageCount(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const getConsultantListing = async (filter_data) => {
    setIsLoading(true);

    let postData = { ...filter_data };

    // if (postData.badge_levels?.length > 0) {
    //   postData.badge_levels = postData.badge_levels.map((l) => l._id);
    // } else {
    //   postData.badge_levels = [];
    // }

    const result = await pods_list_api_v1(
      page,
      rowsPerPage,
      "general",
      postData
    );

    if (result.code === 200) {
      let chipData = { ...filter_data };
      if (chipData.pod_type == "all") {
        delete chipData.pod_type;
      }
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setFilterStateUpdated(chipData);
      setUserList(result.room);
      setTotalPages(result.total_pages);
      setTotalCount(result.room_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (value) => {
    navigate(`/source-pods/edit-pod/${value.room_slug}`, {
      state: { editValues: value.object },
    });
  };

  const handleViewDetail = (value) => {
    navigate(`/source-pods/pods-detail/${value.room_slug}`, {
      state: { ...value, pods_name: "Source Pods" },
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await DeletePod(deleteDoc.room_slug);
    if (result.code === 200) {
      getConsultantListing(filterData);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/source-pods/add-pod`);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getConsultantListing(filterData);
    localStorage.removeItem("pods_filter_data");
    localStorage.setItem("pods_filter_data", JSON.stringify(filterData));
    handleCloseFilterDrawer();
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    getConsultantListing(EMPTY_FILTER);
    setFilterDrawerState(false);
    localStorage.removeItem("pods_filter_data");
  };

  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    getConsultantListing(data);
    localStorage.removeItem("pods_filter_data", JSON.stringify(data));
    setFilterData(data);
    handleCloseFilterDrawer();
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("pods_filter_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    getConsultantListing(filter_data);
  }, [rowsPerPage, page]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <h2>Source Pods</h2>
          </div>

          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-4"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
              <button
                className="small-contained-button me-2"
                onClick={handleNavigate}
              >
                Add Pods
              </button>
            </div>
          </div>

          <FilteredChip
            data={filterStateUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
          <div className="col-12 d-flex justify-content-end">
            <UserListToolbarsForSearch
              filterName={searchText}
              onFilterName={handleSearchText}
              handleSubmit={searchFunction}
            />
          </div>
        </div>
        <div className="row">
          {userList.length == 0 ? (
            <RecordNotFound title="Pods" />
          ) : (
            userList.map((value, index) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={index}>
                  <div className="card mt-4 pods-cards-shadow cursor h-100">
                    <img
                      src={s3baseUrl + value.room_image.thumbnail_2}
                      className="card-img-top pods-image"
                      alt="Pods"
                      onClick={() => handleViewDetail(value)}
                    />
                    <div className="card-body">
                      <div className="row">
                        <div onClick={() => handleViewDetail(value)}>
                          <h3 className="h2-heading pe-4">
                            {htmlDecode(value.title)}
                          </h3>
                        </div>
                        <div className="menu_pod_card_icon">
                          <CustomPopover menu={MENU_OPTIONS} data={value} />
                        </div>
                        <CustomConfirmation
                          open={openDelete}
                          setOpen={setOpenDelete}
                          title={"Are you sure you want to delete this pod?"}
                          handleAgree={handleDelete}
                        />
                      </div>
                      <p className="programme-card-desc mb-3">
                        {htmlDecode(value.short_description)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className="mt-3">
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 200]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
          <Pagination
            count={totalPages + 1}
            page={pageCount}
            defaultPage={0}
            onChange={handleChangePages}
            className="pagination-style"
          />
        </div>
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <PodsFilter
              filterData={filterData}
              handleChange={handleChange}
              setFilterData={setFilterData}
              searchSubmitFilter={searchFunction}
              handleClearFilter={handleClearFilter}
              handleChangeOthers={handleChangeOthers}
              type="source_pods"
            />
          }
        />
      </div>
    </>
  );
}
