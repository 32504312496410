import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomConfirmation from "src/components/menuIcons/CustomConfirmation";
import {
  missionDetailListApi,
  missionListApi,
  missionMemberReportListApi,
} from "src/DAL/AssessmentApi/assessmentApi";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { dd_date_format } from "src/utils/constants";
import MissionReportNew from "./MissionReportNew";
import ActiveLastBreadcrumb from "src/components/BreadCrums";
import RecordNotFound from "src/components/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function areAllArraysEmpty(...arrays) {
  return arrays.every((array) => array.length === 0);
}
export default function MissionReport({ type }) {
  const navigate = useNavigate();
  const { isAccess } = useContentSetting();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const { id, mission_id } = useParams();
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [mcqData, setMcqData] = useState([]);
  const [graphStructure, setGraphStructure] = useState([]);
  const [onScreenQuestion, setOnonScreenQuestion] = useState([]);
  const [userInfo, setUserinfo] = useState({});
  const [Info, setInfo] = useState({});
  const [missionBadges, setMissionBadges] = useState([]);
  const [missionDetail, setMissionDetail] = useState({});

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("report_member", searchText);

    getConsultantListing(searchText);
  };

  const getConsultantListing = async (text) => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      let data = {
        mission_id: mission_id,
        user_id: id,
        tab: "report",
        data: "all",
      };
      const result = await missionDetailListApi(data);
      if (result.code === 200) {
        console.log(
          result,
          "missionMemberReportListApimissionMemberReportListApi"
        );
        setUserinfo(result.mission);
        setInfo(result.member_user);
        setOnonScreenQuestion(result?.on_screen_graph_data);
        setGraphStructure(result.structured_graph_data);
        setMcqData(result.mcq_graph_data);
        setGraphData(result.graph_data);
        setReportData(result.report_data);
        setMissionBadges(result?.mission_badges_earned);
        setMissionDetail(result?.mission);
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleEdit = (value) => {
    navigate(`/mission-member-report/mission/${value._id}`, {
      state: value,
    });
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteDoc.id;
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(1);
    setPage(0);
  };

  useEffect(() => {
    let search_text = localStorage.getItem("report_member");
    if (search_text) {
      setSearchText(search_text);
      getConsultantListing(search_text);
    } else {
      setSearchText("");
      getConsultantListing("");
    }
  }, [page, rowsPerPage]);
  const MENU_OPTIONS = [
    {
      label: "View History",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
  ];
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },

    { id: "Username", label: "Title" },
    { id: "mission_level", label: "Badge Level" },

    { id: "mission_duration", label: "Mission Duration" },
    { id: "mission_reward_coins", label: "Coins Earned" },
    { id: "mission_completed_date", label: "Completed Date" },
    { id: "action", label: "Action", type: "action" },
  ];

  let breadCrumbMenu = [
    {
      title: `Member ${Info ? `(${Info.first_name} ${Info.last_name})` : ""}`,

      navigation:
        type == "complete"
          ? `/mission-member-report`
          : `/inprogress-mission-report`,
      active: false,
    },
    {
      title: `Mission Report ${userInfo ? `(${userInfo.title})` : ""}`,
      active: true,
    },
  ];
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>

        {areAllArraysEmpty(
          reportData,
          mcqData,
          graphStructure,
          onScreenQuestion
        ) ? (
          <div style={{ marginTop: "7%" }}>
            <RecordNotFound title="Data" />
          </div>
        ) : (
          <div className="col-12 mt-4">
            <MissionReportNew
              reportData={reportData}
              mcqData={mcqData}
              graphStructure={graphStructure}
              onScreenQuestion={onScreenQuestion}
              missionDetail={missionDetail}
              missionBadges={missionBadges}
            />
          </div>
        )}
      </div>
    </>
  );
}
