import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import MentionSuggestions from "./MentionSuggestions";
import { get_members_and_delegates_api } from "src/DAL/Community/Community";
import EmojiPickerForEditor from "./EmojiPickerForEditor";
import {
  capitalizeFirst,
  generateRandomID,
  replaceUserNamesWithHTML,
  show_proper_words,
} from "src/utils/constants";

function find_index(str, word, occurrence) {
  let index = -1;
  let startIndex = 0;
  for (let i = 0; i < occurrence; i++) {
    index = str.indexOf(word, startIndex);
    if (index === -1) {
      return -1;
    }
    startIndex = index + word.length;
  }

  return index;
}

const MentionEditor = forwardRef((props, ref) => {
  const {
    setInputs,
    inputs,
    name,
    is_array,
    index,
    setMentionedUsers,
    list_type,
    mentionedUsers,
    value,
    feedLevels,
    event_id,
    placeholder,
    editorClass,
    className,
    addPosition,
    updateValue,
  } = props;
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const [init, setInit] = useState(false);
  const [showsuggestions, setShowsuggestions] = useState(false);
  const [editorText, setEditorText] = useState("");
  const [targetElement, setTargetElement] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [savedSelection, setSavedSelection] = useState(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [cacheData, setCacheData] = useState({});

  useImperativeHandle(ref, () => ({
    clearContent() {
      if (inputRef.current) {
        inputRef.current.innerHTML = "";
        setEditorText("");
        setSelectedUsers([]);
        setMentionedUsers([]);
        setInputs("");
      }
    },
  }));

  const get_members_and_delegates = async (search = "") => {
    let postData = {
      search_text: search,
      list_type,
    };
    if (event_id) {
      postData.event_id = event_id;
    } else {
      postData.community_levels = feedLevels;
    }

    const result = await get_members_and_delegates_api(postData);
    if (result.code === 200) {
      let users = result.users;
      users = result.users.map((user) => {
        const { membership_level_badge_info } = user;
        let profile_image = user.profile_image;
        let user_name = user.first_name + " " + user.last_name;
        if (list_type === "the_cosmos") {
          profile_image = user.image.thumbnail_1;
          user_name += ` (${show_proper_words(user.team_type)})`;
        } else if (!event_id) {
          if (membership_level_badge_info) {
            user_name += ` (${membership_level_badge_info.membership_level_badge_title})`;
          }
        }
        return { ...user, profile_image, user_name };
      });
      setFilteredUsers(users);
      setCacheData({ ...cacheData, [search]: users });
      // setIsLoading(false);
    } else {
      setFilteredUsers([]);
      // setIsLoading(false);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain");
    document.execCommand("insertText", false, text);
  };

  const handleKeyDown = (e) => {
    if (e.key === "@") {
      e.preventDefault();
      const selection = window.getSelection();
      if (!selection.rangeCount) return;
      const range = selection.getRangeAt(0);
      const mentionNode = document.createElement("span");
      mentionNode.className = "mentioning-name";
      mentionNode.textContent = "@";
      range.deleteContents();
      range.insertNode(mentionNode);
      range.setStartAfter(mentionNode);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
      inputRef.current.focus();
      setTimeout(() => {
        setTargetElement(mentionNode);
      }, 0);
    }

    if (e.ctrlKey || e.metaKey) {
      switch (e.key) {
        case "b":
        case "i":
        case "u":
          e.preventDefault();
          break;
        default:
          break;
      }
    }
  };

  const handleKeyUp = () => {
    let mentioning_name = document.querySelector(".mentioning-name");
    if (mentioning_name) {
      const selection = window.getSelection();
      if (selection.rangeCount) {
        const range = selection.getRangeAt(0);
        const node = range.startContainer;
        if (node.nodeType === Node.TEXT_NODE) {
          const parentNode = node.parentNode;
          if (parentNode.classList.contains("mentioning-name")) {
            setTargetElement(parentNode);
          }
        } else if (node.classList?.contains("mentioning-name")) {
          setTargetElement(node);
        }
      }
    } else {
      setTargetElement(null);
    }
  };

  const handleChange = (e) => {
    setInit(true);
    const text = inputRef.current?.innerText;
    setEditorText(text);
    if (is_array) {
      const list = [...inputs];
      list[index][name] = text;
      setInputs(list);
    } else if (inputs) {
      setInputs((old) => ({ ...old, [name]: text }));
    } else {
      setInputs(text);
    }
  };

  const handleEmojiSelect = (emoji) => {
    if (savedSelection) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(savedSelection);
    } else {
      inputRef.current.focus();
    }
    document.execCommand("insertText", false, emoji.native);
  };

  const handleSelect = (user) => {
    setSelectedUsers([...selectedUsers, user]);
    const { first_name, last_name } = user;
    const mentionNode = targetElement;
    if (mentionNode) {
      const random_id = user._id + "-" + generateRandomID(16);
      mentionNode.textContent = `${first_name} ${last_name}`;
      mentionNode.setAttribute("id", random_id);
      mentionNode.classList.remove("mentioning-name");
      mentionNode.classList.add("mentioned-name");
      mentionNode.setAttribute("contentEditable", "false");
      const spaceNode = document.createTextNode("\u00A0");
      mentionNode.parentNode.insertBefore(spaceNode, mentionNode.nextSibling);
      setShowsuggestions(false);
      setTargetElement(null);
      const editorText = inputRef.current.innerText;
      setEditorText(editorText);
      handleChange();
      const range = document.createRange();
      const selection = window.getSelection();
      range.setStartAfter(spaceNode);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
      inputRef.current.focus();
    }
  };

  const handleBlur = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSavedSelection(selection.getRangeAt(0));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setShowsuggestions(false);
      }
    };

    const handleClickInside = (event) => {
      if (event.target.getAttribute("class")?.includes("mentioning-name")) {
        const mentionNode = event.target;
        setTargetElement(mentionNode);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("click", handleClickInside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("click", handleClickInside);
    };
  }, [inputRef, suggestionsRef]);

  useEffect(() => {
    get_members_and_delegates();

    //handle the placeholder
    const handlePlaceholder = () => {
      const editor = inputRef.current;
      if (editor && editor.innerText.trim() === "") {
        editor.classList.add("empty");
      } else {
        editor.classList.remove("empty");
      }
    };

    handlePlaceholder();
    document.addEventListener("input", handlePlaceholder);
    return () => {
      document.removeEventListener("input", handlePlaceholder);
    };
  }, []);

  useEffect(() => {
    //set the initial value of the editor
    if (value && !init && inputRef.current) {
      setInit(true);
      const editor = inputRef.current;
      editor.innerHTML = `${replaceUserNamesWithHTML(
        value,
        mentionedUsers,
        true
      )} `;
      setEditorText(replaceUserNamesWithHTML(value, mentionedUsers, true));
      setSelectedUsers(mentionedUsers);
    }
  }, [value, inputRef]);

  useEffect(() => {
    if (value && inputRef.current) {
      setInit(true);
      const editor = inputRef.current;
      editor.innerHTML = `${replaceUserNamesWithHTML(
        value,
        mentionedUsers,
        true
      )} `;
      setEditorText(replaceUserNamesWithHTML(value, mentionedUsers, true));
      setSelectedUsers(mentionedUsers);
    }
  }, [updateValue]);

  useEffect(() => {
    // Fixing the overflow issue in the popup so that the suggestions are visible
    let popup_classes = ".create-post-title-form .modal-theme";
    let popup_element = document.querySelector(popup_classes);
    if (popup_element) {
      popup_element.style.overflow = showsuggestions ? "visible" : "auto";
    }
    if (showsuggestions) {
      let suggestions = document.querySelector(".show-suggestions-names");
      const suggestion_width = suggestions.offsetWidth;
      const suggestion_left = suggestions.style.left;
      const rect = targetElement?.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      let target_right = viewportWidth - rect.left;
      if (viewportWidth <= suggestion_width) {
        suggestions.style.left = `${0}px`;
        return;
      }
      if (suggestion_width > target_right) {
        let reduce_left = suggestion_width - target_right;
        let left = parseFloat(suggestion_left) - reduce_left - 10;
        suggestions.style.left = `${left}px`;
      }

      let sidebar = document.querySelector("#sidebar-right");
      if (sidebar?.style) {
        sidebar.style.zIndex = -1;
      }
      document.querySelectorAll(".profile-cards").forEach((div) => {
        if (!div.contains(suggestions)) {
          div.style.zIndex = -1;
        }
      });
      document.querySelectorAll(".insert-reply-button").forEach((div) => {
        if (!div.contains(suggestions)) {
          div.style.zIndex = 0;
        }
      });
    } else {
      let sidebar = document.querySelector("#sidebar-right");
      if (sidebar?.style) {
        sidebar.style.zIndex = 1020;
      }
      document.querySelectorAll(".profile-cards").forEach((div) => {
        div.style.zIndex = 1;
      });
      document.querySelectorAll(".insert-reply-button").forEach((div) => {
        div.style.zIndex = 2;
      });
    }
  }, [showsuggestions]);

  useEffect(() => {
    const editor = inputRef.current;
    const mentionNode = editor.querySelector(".mentioning-name");
    if (mentionNode && targetElement) {
      const mentionEditorRect = inputRef.current?.getBoundingClientRect();
      let text = targetElement.textContent.slice(1);
      if (cacheData[text.toLowerCase()]) {
        setFilteredUsers(cacheData[text.toLowerCase()]);
      } else {
        setFilteredUsers((old) =>
          old.filter(
            (user) =>
              user.user_name.toLowerCase().includes(text.toLowerCase()) ||
              user.email.toLowerCase().includes(text.toLowerCase())
          )
        );
        get_members_and_delegates(text.toLowerCase());
      }
      if (targetElement) {
        const rect = targetElement?.getBoundingClientRect();
        let left = rect.left - mentionEditorRect.left;
        if (left >= 0) {
          let top = rect.bottom - mentionEditorRect.top;

          //sometimes we need to adjust spacing
          let add_top = 0;
          let add_left = 0;
          if (addPosition?.top) {
            add_top = addPosition?.top;
          }
          if (addPosition?.left) {
            add_left = addPosition?.left;
          }
          left += add_left;
          top += add_top;

          setPosition({ left, top });
          setTimeout(() => {
            setShowsuggestions(true);
          }, 200);
        }
      }
    } else {
      setShowsuggestions(false);
    }

    // we are finding the mentioned users by the id of the mentioned node so that if sometimes we delete a user from the mentioned users list we should be able to remove the mentioned users array
    const mentionNodesAll = editor.querySelectorAll(".mentioned-name");
    let nodes = [];
    let inner_text = inputRef.current?.innerText;
    console.log(inner_text, "inner_textinner_textinner_text");
    Array.from(mentionNodesAll).forEach((node) => {
      let _id = node.getAttribute("id");
      let filter_user = nodes.filter((user) => _id.includes(user._id));
      let find = selectedUsers.find((user) => _id.includes(user._id));

      if (find) {
        const { first_name, last_name } = find;
        let occurrence = 1;
        let length;
        let full_name = `${first_name} ${last_name}`;

        if (filter_user.length > 0) {
          occurrence = filter_user.length + 1;
        }
        // Find the offset for the occurrence
        let offset = find_index(inner_text, full_name, occurrence);

        // Determine if the mention includes the last name
        let name_length = offset + first_name.length + last_name.length + 1;
        let text_string = inner_text.substring(offset, name_length);

        // Normalize spaces by replacing non-breaking spaces with regular spaces
        full_name = full_name.replace(/\u00A0/g, " ");
        text_string = text_string.replace(/\u00A0/g, " ");

        // Trim strings for comparison
        full_name = full_name.trim();
        text_string = text_string.trim();

        let is_full_name = text_string === full_name;

        // if (is_full_name) {
        //   length = full_name.length;
        // } else {
        //   length = first_name.length;
        // }
        length = full_name.length;
        nodes.push({ ...find, length, offset, id: _id });
      }
    });
    console.log(nodes, "nodesnodesnodesnodesnodesnodes");
    setMentionedUsers(nodes);
  }, [targetElement, inputRef, editorText]);

  return (
    <div className={`mention-editor ${editorClass || ""}`}>
      <div
        contentEditable
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onInput={handleChange}
        className={`mention-editor-box ${className || ""}`}
        ref={inputRef}
        data-placeholder={placeholder}
        onBlur={handleBlur}
      ></div>
      {showsuggestions && (
        <MentionSuggestions
          users={filteredUsers}
          handleSelect={handleSelect}
          position={position}
          suggestionsRef={suggestionsRef}
        />
      )}
      <EmojiPickerForEditor handleEmojiSelect={handleEmojiSelect} />
    </div>
  );
});

export default MentionEditor;
