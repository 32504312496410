// here we add all base urls and keys

// Base URLS
// export const baseUri = process.env.REACT_APP_API_BASE_URL;
// export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
// here we add all base urls and keys
import { _get_consultant_from_localStorage } from "src/DAL/localstorage/LocalStorage";
import { update_root_value } from "src/utils/domUtils";

// Base URLS
export const baseUri = process.env.REACT_APP_API_BASE_URL;
// export const baseUri = "http://192.168.1.72:4615/";
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
export const client_url = process.env.REACT_APP_CLIENT_URL;
export const project_name = process.env.REACT_APP_PROJECT_NAME;
export const socketBaseUri = process.env.REACT_APP_API_SOCKET_BASE_URL;
export const projectMode = process.env.REACT_APP_PROJECT_MODE;

const get_consultant = _get_consultant_from_localStorage();

export const material_theme_palate = {
  lighter: "#d4bff2",
  light: "#ede7f6",
  main: "#1a93a9",
  dark: "#e4c073",
  darker: "#e4c073",
  contrastText: "#fff",
};
export const OwnerName = () => {
  return "Kim";
};

export const ownerName = OwnerName() + "'s";
export const ownerCompleteName = OwnerName();

export const get_app_logo = () => {
  return get_consultant?.brand_logo;
};

export const get_app_headers = () => {
  return {
    title: get_consultant?.meta_title,
    description: get_consultant?.meta_description,
    fav_icon: get_consultant?.brand_favicon,
  };
};

if (project_name == "DD") {
  material_theme_palate.main = "#e4c073";
} else {
  material_theme_palate.main = "#1a93a9";
}

material_theme_palate.main = "#e4c073";
/* Main theme color */
update_root_value("--portal-theme-primary", "#e4c073");
update_root_value("--text-primary-color", "white");
update_root_value("--text-secondary-color", "rgb(150, 150, 150)");
/* Main theme color end */

/* Main background color*/
update_root_value("--background-primary-color", "#121826");
update_root_value("--background-secondary-color", "#1D2537");

/* Main background color end*/

/* Sidebar Properties */
update_root_value("--sidebars-text-color", "white");
update_root_value("--sidebars-background-color", "#1D2537");
/* Sidebar Properties */

/* Popup / Popover properties  */
update_root_value("--popup-background-color", "#1D2537");
update_root_value("--popup-text-color", "white");
/* Popup / Popover properties end  */

/* Element properties */
update_root_value("--input-text-color", "#fff");
update_root_value("--input-placeholder-color", "#fff");
update_root_value("--input-background-primary", "#1D2537");
update_root_value("--input-background-secondary", "#1D2537");
/* Element properties ends*/

/* Button Properties */

update_root_value("--button-background-color", "#121826");
update_root_value("--audio-color", "#222b3e");
update_root_value("--calendar-selected-box", "#8b8b8b");
